<template>
  <div class="form">
    <div class="wrapper illustration illustration_dots">
      <Backlink title="questionnaire" service="credit" backlink="/credit/expense" />
      <div class="content">
        <AboutExpenses service="credit" nextlink="/credit/obligations" />
      </div>
    </div>
  </div>
</template>

<script>
import Backlink from '@/components/base/Backlink.vue'
import AboutExpenses from '@/components/service/AboutExpenses.vue'

export default {
  name: 'Credit_about_expences',
  components: { Backlink, AboutExpenses }
}
</script>
